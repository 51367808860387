.filter-infos {
  text-align: right;
}
.show-filter-container {
  text-align: center;
}
.orange-filter-container {
  margin-bottom: 15px;
}
.notice-popup {
  width: 300px !important;
}
