.orange-list-container {
  padding-top: 20px;
}
.address-date {
  font-size: small;
  text-align: right;
  font-style: italic;
  color: #888;
}
.address-message {
  width: 100%;
  padding: 6px !important;
}
.orange-table-container {
  position: relative;
  min-height: 60vh;
  /* max-height: calc(100vh - 145px); */
  overflow-y: auto;
}

.orange-table-container table {
  width: 100%;
}

.orange-table-container thead {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.orange-table-container thead th {
  border-top: 1px solid #cacaca;
}

.orange-table-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}

.pagination-container {
  position: sticky;
  bottom: 0px;
  z-index: 10;
}
