body {
  background-image: url("../public/white-texture.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
  padding: 0;
  margin: 0;

  font-family: system-ui, Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}
a {
  color: #2c266d;
}
a:hover {
  color: #a333c8;
}
.egg-title {
  text-transform: uppercase;
  line-height: 36px;
  font-weight: bold;
}
.justified {
  text-align: justify;
}
.bold {
  font-weight: bold;
}
.ui.pointing.menu,
html {
  padding: 0;
  margin: 0;
}
.app-container {
  width: 100%;
  margin: 0px;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.app-body {
  margin-top: 0 !important;
  overflow: hidden;
  background: transparent !important;
}
.app-body.egg {
  height: calc(100% - 40px);
  overflow: auto;
  position: static !important;
  scroll-behavior: smooth;
}
.auth-header {
  height: 40px;
}
.centered {
  text-align: center;
}

.centered .image {
  display: inline !important;
}
.ui.primary.button {
  background-color: #2c266d;
}
.egg-violet {
  color: #2c266d !important;
}
.ui.primary.button:hover {
  background-color: #534abd;
}
.submit-folder-form {
  padding-top: 0 !important;
}
.error.required {
  position: relative;
}
.ui.pointing.below {
  top: -13px;
  position: absolute;
  left: -2px;
}
.files-container {
  padding-top: 4px;
}
.pagination-container {
  text-align: center;
}

.ui.secondary.pointing.menu .active.item {
  border-color: #5f4e99;
  color: #534192;
}
